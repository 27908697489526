import client, {
  storageKey,
  getRestClient,
  BASE_URL
} from './feathers-rest-client';

/**
 * Login user with email and password
 * @param {String} email
 * @param {String} password
 * @returns
 */
export const loginWithEmailAndPassword = async (email, password) => {
  return await client.authenticate({
    strategy: 'local',
    email,
    password
  });
};

export const logout = async () => {
  try {
    await client.logout();
  } catch (error) {
    if (error.code === 404) {
      localStorage.removeItem(storageKey);
    }
  }
  return true;
};

export const reauth = async () => {
  return client.authentication.reAuthenticate();
};

export const sentResetPassword = async (email, recaptchaToken) => {
  return client.service('auth-management').create({
    action: 'sendResetPwd',
    value: {
      email
    },
    capchaToken: recaptchaToken
  });
};

export const resetPassword = async (token, password) => {
  return client.service('auth-management').create({
    action: 'resetPwdLong',
    value: {
      token,
      password
    }
  });
};

export const cleanup = async (data) => {
  return client.service('cleanup').create(data);
};

export const getAccounts = async (query) => {
  return client.service('accounts').find({
    query
  });
};

export const updateAccount = async (id, data) => {
  return client.service('accounts').patch(id, data);
};

export const getAccountWorkflows = async (query) => {
  return client.service('account-workflow').find({
    query
  });
};

export const createAccountWorkflow = async (data) => {
  return client.service('account-workflow').create(data);
};

export const deleteAccountWorkflow = async (id, query) => {
  return client.service('account-workflow').remove(id, {
    query
  });
};

export const getAccountWorkflowSteps = async (query) => {
  return client.service('account-workflow-steps').find({
    query
  });
};

export const createAccountWorkflowStep = async (data) => {
  return client.service('account-workflow-steps').create(data);
};

export const updateAccountWorkflowStep = async (id, data) => {
  return client.service('account-workflow-steps').patch(id, data);
};

export const deleteAccountWorkflowStep = async (id, query) => {
  return client.service('account-workflow-steps').remove(id, {
    query
  });
};

export const getCatalogItems = async (query) => {
  return client.service('catalog-item').find({
    query
  });
};

export const createCatalogItem = async (data) => {
  return client.service('catalog-item').create(data);
};

export const updateCatalogItem = async (id, data) => {
  return client.service('catalog-item').patch(id, data);
};

export const deleteCatalogItem = async (id, query) => {
  return client.service('catalog-item').remove(id, {
    query
  });
};

/**
 * Create (signup) user in the system
 */
export const createUser = async (data) => {
  return client.service('users').create(data);
};

export const getUsers = async (query) => {
  return client.service('users').find({
    query
  });
};

export const verifyUser = async (email, token) => {
  return client.service('auth-management').create({
    action: 'verifySignupShort',
    value: {
      user: {
        email
      },
      token
    }
  });
};

export const updateUser = async (id, data) => {
  return client.service('users').patch(id, data);
};

export const resendVerificationCode = async (email) => {
  return client.service('auth-management').create({
    action: 'resendVerifySignup',
    value: {
      email
    }
  });
};

export const getVendorPrimaryCategories = (params) => {
  return client.service('vendor/categories').find({
    query: params
  });
};

export const createVendorPrimaryCategory = (data) => {
  return client.service('vendor/categories').create(data);
};

export const updateVendorPrimaryCategory = (categoryId, data) => {
  return client.service('vendor/categories').patch(categoryId, data);
};

export const getVendorSecondaryCategories = () => {
  return client.service('vendor/sub-categories').find();
};

export const createVendorSecondaryCategory = (data) => {
  return client.service('vendor/sub-categories').create(data);
};

export const updateVendorSecondaryCategory = (categoryId, data) => {
  return client.service('vendor/sub-categories').patch(categoryId, data);
};

export const createVendor = (data) => {
  return client.service('vendors').create(data);
};

export const updateVendor = (id, data, query) => {
  return client.service('vendors').patch(id, data, {
    query
  });
};

export const getVendors = (query) => {
  return client.service('vendors').find({
    query
  });
};

export const getVendorHistory = (query) => {
  return client.service('vendor-history').find({
    query
  });
};

export const searchVendors = (searchText) => {
  return client.service('vendors').find({
    query: {
      $search: {
        compound: [
          {
            should: [
              {
                autocomplete: {
                  query: searchText,
                  path: '_id',
                  fuzzy: {
                    maxEdits: 2,
                    prefixLength: 2
                  }
                }
              },
              {
                autocomplete: {
                  query: searchText,
                  path: 'businessName',
                  fuzzy: {
                    maxEdits: 2,
                    prefixLength: 2
                  }
                }
              }
            ]
          }
        ]
      }
    }
  });
};

export const getVendor = (id, query) => {
  return client.service('vendors').get(id, {
    query
  });
};

export const getVendorsStatusCount = () => {
  return client.service('vendors/status-count').create({});
};

export const executeVendorDecision = (data) => {
  return client.service('vendors/decision').create(data);
};

export const createAccount = (data) => {
  return client.service('accounts').create(data);
};

export const createCustomer = (data) => {
  return client.service('accounts').create(data);
};

export const getInvitations = (query) => {
  return client.service('invitations').find({
    query
  });
};

export const createInvitation = (data) => {
  return client.service('invitations').create(data);
};

export const deleteInvitation = (id, accountId) => {
  return client.service('invitations').remove(id, {
    query: {
      accountId
    }
  });
};

/**
 *
 * @param {Array} roles - array of roles to retrieve
 * @param {*} accountId - account id
 */
export const getUserRoles = (query) => {
  return client.service('user-roles').find({
    query
  });
};

export const deleteUserRole = (id, query) => {
  return client.service('user-roles').remove(id, {
    query
  });
};

export const createUserRole = (data, query) => {
  return client.service('user-roles').create(data, {
    query
  });
};

export const getOrders = (query) => {
  return client.service('orders').find({
    query
  });
};

export const createOrder = (data) => {
  return client.service('orders').create(data);
};

export const updateOrder = (id, data) => {
  return client.service('orders').patch(id, data);
};

export const createOrderDocument = (data, query) => {
  return client.service('order-documents').create(data, {
    query
  });
};

export const getOrderApprovalHistory = (query) => {
  return client.service('order-approval-history').find({
    query
  });
};

export const countOrders = (data) => {
  return client.service('orders/count').create(data);
};

export const createOrderWorkflowSubmission = (data, query) => {
  return client.service('order-submission-workflow').create(data, {
    query
  });
};

export const createOrderSubmission = (data) => {
  return client.service('order-submission').create(data);
};

export const updateOrderSubmission = (id, data) => {
  return client.service('order-submission').patch(id, data);
};

export const createOrderApprovalDecision = (data) => {
  return client.service('order-approval-decision').create(data);
};

export const createOrderSubmissionDecision = (data) => {
  return client.service('order-submission-decision').create(data);
};

export const deleteOrderSubmission = (id, query) => {
  return client.service('order-submission').remove(id, {
    query
  });
};

export const countOrderSubmissions = (data) => {
  return client.service('order-submission/count').create(data);
};

export const createOrderSubmissionDocument = (data, query) => {
  return client.service('order-submission-documents').create(data, {
    query
  });
};

export const getOrderSubmissions = (query) => {
  return client.service('order-submission').find({
    query
  });
};

export const getOrderSubmissionWorkflow = (query) => {
  return client.service('order-submission-workflow').find({
    query
  });
};

export const getOrderSubmission = (id, query) => {
  return client.service('order-submission').get(id, {
    query
  });
};

export const getOrderSubmissionHistory = (query) => {
  return client.service('order-submission-history').find({
    query
  });
};

export const exportOrderSubmissions = (data, query) => {
  return client.service('order-submission/export').create(data, {
    query
  });
};

export const resendInvitation = (id, accountId) => {
  return client.service('invitations').patch(id, {
    resend: true,
    accountId
  });
};

export const getInvite = async (email, token) => {
  const invites = await client.service('accept-invite').find({
    query: {
      email,
      token
    }
  });

  if (invites?.length) {
    return invites[0];
  }

  return null;
};

export const acceptInvite = (email, token, decision) => {
  return client.service('accept-invite').create({
    email,
    token,
    decision
  });
};

export const downloadFile = async (id) => {
  // get the file by id
  const file = await client.service('files').get(id);

  // get the file content by file url
  const restClient = getRestClient();
  const { data } = await restClient.get(file.url, {
    responseType: 'arraybuffer'
  });

  return data;
};

export const superAdminCreateUser = (data) => {
  return client.service('super-admin/create-user').create(data);
};

// export const getVendorCategories = (query) => {
//   return (
//     client.service('vendor-categories'),
//     find({
//       query
//     })
//   );
// };

// export const getVendorSubCategories = (query) => {
//   return client.service('vendor')
// };

export const modifyVendorId = (data) => {
  return client.service('modify-vendor-id').create(data);
};

export const generateIrsFile = async (accountId, data) => {
  return client.service('irs-file-gen').create(data, {
    query: {
      accountId
    }
  });
  // const accessToken = await client.authentication.getAccessToken();
  // const restClient = getRestClient();
  // return restClient.post(`${BASE_URL}/irs-file-gen`, data, {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     Authorization: accessToken
  //   },
  //   params: {
  //     accountId
  //   }
  // });
};

export const uploadFiles = async (
  files,
  accountId,
  progressFn = null,
  title = undefined,
  description = undefined
) => {
  const accessToken = await client.authentication.getAccessToken();
  const restClient = getRestClient();

  const formData = new FormData();

  files.forEach((file) => {
    formData.append('file', file);
  });

  const config = {
    onUploadProgress: function (progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      if (progressFn) {
        progressFn(percentCompleted);
      }
    }
  };
  formData.append('accountId', accountId);

  const { data } = await restClient.post(`${BASE_URL}/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: accessToken
    },
    params: {
      accountId,
      title,
      description
    },
    ...config
  });

  return data;
};
